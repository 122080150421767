<template>
  <div>
    <TosListVuetify serviceName="vessel" entityName="vessels" :headers="headers">
      <template #customFilters="slotProps">
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabel('Name')" v-model="slotProps.data.name" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('IMO')" v-model="slotProps.data.imo" />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.flag="slotProps">
        <div>{{ countryFlagsMap&&countryFlagsMap[slotProps.value] ? countryFlagsMap[slotProps.value].country : "" }}</div>
      </template>
      <template v-slot:editF="slotProps">
        <v-container fluid>
          <v-row>
            <v-col>
              <v-text-field :label="tLabel('Name')" v-model="slotProps.data.name" :rules="[rules.required]" :error-messages="slotProps.errors.name" />
            </v-col>
            <v-col>
              <v-text-field :label="tLabel('IMO')" v-model="slotProps.data.imo" :rules="[rules.required]" :error-messages="slotProps.errors.imo" />
            </v-col>
            <v-col>
              <v-text-field :label="tLabel('Call sign')" v-model="slotProps.data.callSign" :error-messages="slotProps.errors.callSign" />
            </v-col>
            <v-col>
              <v-autocomplete :label="tLabel('Flag')" v-model="slotProps.data.flag" :items="countryFlags" :rules="[rules.required]" :error-messages="slotProps.errors.flag" item-value="code" item-text="country">       
                <template v-slot:item="{ item }"> ({{ item.code }}) {{ item.country }} </template>
                <template v-slot:selection="{ item }"> ({{ item.code }}) {{ item.country }} </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TosListVuetify>
  </div>
</template>

<script>
import TosListVuetify from "@/components/anagrafiche/TosListVuetify";
import FormatsMixins from "@/mixins/FormatsMixins";

export default {
  name: "Vessels",
  data() {
    return {
      countryFlagsMap: {},
      countryFlags: [],
      headers: [
        { text: "", value: "action" },
        // { text: "ID", value: "id" },
        { text: `${this.tLabel("Name")}`, value: "name" },
        { text: `${this.tLabel("IMO")}`, value: "imo" },
        { text: `${this.tLabel("Call sign")}`, value: "callSign" },
        { text: `${this.tLabel("Flag")}`, value: "flag" },
      ],
    };
  },
  mounted() {
    this.loadCombo();
  },

  methods: {
    async loadCombo() {
      this.countryFlags = new Array();
      this.countryFlagsMap = await this.$api.vesselData.getCountryFlags();
      let keys = Object.keys(this.countryFlagsMap).sort();
      keys.forEach((cfk) => {
        this.countryFlags.push(this.countryFlagsMap[cfk]);
      });
    },
  },
  components: { TosListVuetify },
  mixins: [FormatsMixins],
};
</script>
